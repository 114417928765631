import React from 'react'

const Para_2 = () => {
    const para_2_style = {
        width: "100%",
        minHeight: "20vh",
        background: "#FFFFFF",
        color: "#333333",
        fontSize: "1.22rem",
        fontFamily: "Helvetica",
        fontWeight: "medium",
        margin: "20px 0px",
        padding: "20px 20px",
        borderRadius: "10px",
        wordSpacing: ".1rem"
    }
    return (
        <>
            <p style={para_2_style}>The confined space described in this permit is safe to enter using the processes,
            control measures and precautions listedted above. Workers required to work in the confined
                space have been advised of, and understand, the requirements and risks of the work.</p>
        </>
    )
}
export default Para_2;